import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PreferencesService } from '../services/preferences.service';
import { STORAGE_BOOK_SHELF_SETTINGS } from './constants';

export enum BookShelfDisplayType {
  Line = 'line',
  Grid = 'grid',
}

interface BookShelfSettings {
  displayType: BookShelfDisplayType;
}

@Injectable({ providedIn: 'root' })
export class BookShelfSettingsState {
  constructor(private preferenceService: PreferencesService) {}

  private readonly STORAGE_KEY = STORAGE_BOOK_SHELF_SETTINGS;

  bookShelfSettings$ = new BehaviorSubject<BookShelfSettings>({ displayType: BookShelfDisplayType.Grid });

  async initialize(): Promise<void> {
    const storedSettings = await this.get();
    storedSettings ? this.bookShelfSettings$.next(storedSettings) : await this.set(this.bookShelfSettings$.value);
  }

  private async get(): Promise<BookShelfSettings | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<BookShelfSettings>): Promise<void> {
    this.bookShelfSettings$.next({ ...this.bookShelfSettings$?.value, ...settings });
    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(this.bookShelfSettings$.value));
  }
}
