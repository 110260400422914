import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { BookFiltersState } from '../../../../store/book-filters.state';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-language-book-filters-mobile-modal',
  templateUrl: './language-book-filters-mobile-modal.page.html',
  styleUrls: ['../filters-mobile-modal.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class LanguageBookFiltersMobileModalPage {
  constructor(
    private bookFilters: BookFiltersState,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
  ) {}

  languageFilters = this.bookFilters.bookFilters$.value.languages;
  selectedLanguageCode = this.bookFilters.bookFilters$.value.selectedLanguage?.code || null;

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.ContentLanguageSelectionModal });
  }

  closeModal(): void {
    void this.modalController.dismiss();
  }

  async onSelectedLanguageChange(languageCode: string | null): Promise<void> {
    this.selectedLanguageCode = languageCode;

    const newSelectedLanguageCode = this.languageFilters.find(language => language.code === this.selectedLanguageCode);

    if (this.bookFilters.bookFilters$.value.selectedLanguage !== newSelectedLanguageCode) {
      await this.bookFilters.set({ selectedLanguage: newSelectedLanguageCode });
      this.closeModal();
    }
  }
}
