import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonRadio, IonRadioGroup, ModalController } from '@ionic/angular/standalone';
import { BookFiltersState } from '../../../../store/book-filters.state';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../button/button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-grade-book-filters-desktop-modal',
  templateUrl: './grade-book-filters-desktop-modal.page.html',
  styleUrls: ['../filters-desktop-modal.scss'],
  standalone: true,
  imports: [IonRadioGroup, TranslateModule, IonRadio, ButtonComponent],
})
export class GradeBookFiltersDesktopModalPage {
  constructor(
    private bookFilters: BookFiltersState,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
  ) {}

  gradeFilters = this.bookFilters.bookFilters$.value.grades;
  selectedGradeId = this.bookFilters.bookFilters$.value.selectedGrade?.id || null;

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.ContentGradeSelectionModal });
  }

  async applyFilter(): Promise<void> {
    const newSelectedGradeId = this.gradeFilters.find(grade => grade.id === this.selectedGradeId);

    if (this.bookFilters.bookFilters$.value.selectedGrade !== newSelectedGradeId) {
      await this.bookFilters.set({ selectedGrade: newSelectedGradeId });
    }
    this.closeModal();
  }

  closeModal(): void {
    void this.modalController.dismiss();
  }

  onSelectedGradeChange(event: CustomEvent): void {
    this.selectedGradeId = event.detail.value;
  }
}
