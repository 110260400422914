<div class="filters-mobile-container" data-cy="grade-mobile-modal">
  <div class="title">{{ 'PWA_libraryFilters_ageRange_header' | translate }}</div>

  <div class="options-container">
    <div class="option" (click)="onSelectedGradeChange(null)" data-cy="grade-mobile-option">
      {{ 'PWA_libraryFilters_ageRange_selection_all' | translate }}
      @if (!selectedGradeId) {
        <img src="assets/icon/blue-check.svg" alt="" />
      }
    </div>

    @for (gradeFilter of gradeFilters; track $index) {
      <div class="option" (click)="onSelectedGradeChange(gradeFilter.id)" data-cy="grade-mobile-option">
        {{ gradeFilter.name.default }}
        @if (selectedGradeId === gradeFilter.id) {
          <img src="assets/icon/blue-check.svg" alt="" />
        }
      </div>
    }
  </div>
</div>
