import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonRadio, IonRadioGroup, ModalController } from '@ionic/angular/standalone';
import { BookFiltersState } from '../../../../store/book-filters.state';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../button/button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-language-book-filters-desktop-modal',
  templateUrl: './language-book-filters-desktop-modal.page.html',
  styleUrls: ['../filters-desktop-modal.scss'],
  standalone: true,
  imports: [IonRadioGroup, TranslateModule, IonRadio, ButtonComponent],
})
export class LanguageBookFiltersDesktopModalPage {
  constructor(
    private bookFilters: BookFiltersState,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
  ) {}

  languageFilters = this.bookFilters.bookFilters$.value.languages;
  selectedLanguageCode = this.bookFilters.bookFilters$.value.selectedLanguage?.code || null;
  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.ContentLanguageSelectionModal });
  }

  async applyFilter(): Promise<void> {
    const newSelectedLanguageCode = this.languageFilters.find(language => language.code === this.selectedLanguageCode);

    if (this.bookFilters.bookFilters$.value.selectedLanguage !== newSelectedLanguageCode) {
      await this.bookFilters.set({ selectedLanguage: newSelectedLanguageCode });
    }
    this.closeModal();
  }

  closeModal(): void {
    void this.modalController.dismiss();
  }

  onSelectedLanguageChange(event: CustomEvent): void {
    this.selectedLanguageCode = event.detail.value;
  }
}
