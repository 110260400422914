@for (book of books; track book.uuid) {
  <div [@fadeInOut] #bookContainer class="book" [class.desktop]="(appService.isMobile$ | async) === false" data-cy="book-grid-book">
    @if (shelfType === ShelfType.Favorites) {
      <app-button
        data-cy="book-grid-like-btn"
        class="like"
        [shape]="'circle'"
        [icon]="'assets/icon/heart-red.svg'"
        [color]="'white'"
        [size]="'xxsmall'"
        (clicked)="like(book.uuid, false)" />
    }
    @defer (on viewport(bookContainer)) {
      <img [routerLink]="'/book/' + book.uuid + '/details'" src="{{ book | compressedImage }}" alt="{{ book?.title }}" class="cover" />
    }

    <div [routerLink]="'/book/' + book.uuid + '/details'" class="book-title" [class.mobile]="appService.isMobile$ | async">{{ book.title }}</div>
  </div>
}
