<div class="filters-desktop-container" data-cy="grade-desktop-modal">
  <div class="close-modal" (click)="closeModal()">
    <img src="assets/icon/blue-close.svg" alt="" />
  </div>

  <div class="title">{{ 'PWA_libraryFilters_ageRange_desktop_header' | translate }}</div>
  <div class="description">{{ 'PWA_libraryFilters_ageRange_desktop_subtitle' | translate }}</div>

  <ion-radio-group [value]="selectedGradeId" (ionChange)="onSelectedGradeChange($event)">
    <ion-radio mode="md" justify="start" label-placement="end" [value]="null" data-cy="grade-desktop-option">
      {{ 'PWA_libraryFilters_ageRange_selection_all' | translate }}
    </ion-radio>
    @for (gradeFilter of gradeFilters; track $index) {
      <ion-radio mode="md" justify="start" label-placement="end" [value]="gradeFilter.id" data-cy="grade-desktop-option">
        {{ gradeFilter.name.default }}
      </ion-radio>
    }
  </ion-radio-group>

  <app-button [label]="'PWA_libraryFilters_ageRange_desktop_CTA_confirm' | translate" (clicked)="applyFilter()" data-cy="grade-desktop-confirm-btn" />

  <div class="cancel" (click)="closeModal()">{{ 'PWA_libraryFilters_ageRange_desktop_CTA_link_cancel' | translate }}</div>
</div>
